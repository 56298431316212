<template>
  <b-card :title="$i18n.messages.Pedidos">
    <!--  <b-row v-if="user.rol_id == 2">
      <b-col md="2" sm="4" class="my-1">
        <b-form-group
          class="mb-0"
          :label="$i18n.messages.PorPagina"
          label-size="sm"
          label-for="perPageSelect"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="4" class="my-1">
        <b-form-group
          :label="$i18n.messages.Orden"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">
                  -- none --
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :options="orderOptions"
              :disabled="!sortBy"
              class="w-25"
            >
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="3" class="my-1">
        <b-form-group
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="text-right mb-0"
        >
          <b-button-group size="sm">
            <b-button size="sm" variant="primary" @click="listOrders('client')">
              {{ $i18n.messages.Buscar }}
            </b-button>
            <b-button size="sm" @click="resetFilters()">
              {{ $i18n.messages.LimpiarFiltros }}
            </b-button>
          </b-button-group>
        </b-form-group>
      </b-col>

      <b-col cols="12 my-2">
        <b-table striped responsive :items="items" :fields="fields">
          <template #cell(estado)="data">
            <b-badge pill :variant="config.colorPedidos[data.value]">
              {{ config.estadosPedidos[data.value] }}
            </b-badge>
          </template>
          <template #cell(totalPicking)="data">
            <b-badge
              pill
              :variant="data.item.totalPicking ? 'danger' : 'light'"
            >
              <span v-if="data.item.totalPicking">{{
                $i18n.messages.ConRecargoPicking
              }}</span>
              <span v-else>{{ $i18n.messages.SinRecargoPicking }}</span>
            </b-badge>
          </template>
          <template #cell(actions)="data">
            <router-link
              :to="{ name: 'order', params: { id: data.item.idPedido } }"
            >
              <b-button class="btn-action" style="min-width: 100px;">
                {{ $i18n.messages.DetailOrder }}
              </b-button>
            </router-link>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row> -->
    <b-row>
      <b-row>
        <b-col md="6" sm="6" class="my-2">
          <b-form-group class="mb-0" label-size="lg" label-for="perPageSelect">
            <b-input-group size="md" style="margin-top:14px;margin-left:14px;">
              <b-form-input id="filterInput" v-model="search" type="search" />
              <b-input-group-append>
                <b-button variant="primary" @click="listOrders()">
                  {{ $i18n.messages.Buscar }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="6" class="my-1">
          <b-input-group size="sm">
            <label class="w-50">
              {{ $i18n.messages.Desde }}
            </label>
            <label class="w-50">
              {{ $i18n.messages.Hasta }}
            </label>
          </b-input-group>
          <b-form-group class="mb-0" label-size="lg">
            <b-input-group size="md">
              <flat-pickr
                v-model="fecha_ini"
                id="fecha_ini"
                class="form-control w-50"
                :config="{
                  dateFormat: 'd/m/y',
                }"
              />
              <flat-pickr
                v-model="fecha_fin"
                id="fecha_fin"
                class="form-control w-50"
                :config="{
                  dateFormat: 'd/m/y',
                }"
              />
            </b-input-group>
          </b-form-group> </b-col
      ></b-row>
      <b-col cols="12 my-2">
        <b-table
          striped
          responsive
          :stacked="stacked"
          :items="items"
          :fields="fieldsAll"
        >
          <template #cell(Estado)="data">
            <b-badge pill :variant="config.colorPedidos[data.value]">
              {{ config.estadosPedidos[data.value] }}
            </b-badge>
          </template>
          <template #cell(actions)="data">
            <router-link :to="{ name: 'order', params: { id: data.item } }">
              <b-button class="btn-action" style="min-width: 100px;">
                {{ $i18n.messages.DetailOrder }}
              </b-button>
            </router-link>
          </template>
        </b-table>
      </b-col>

      <!--   <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col> -->
    </b-row>
    <!--    </b-tab>
    </b-tabs> -->
  </b-card>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BButton,
  BCard,
  BBadge,
  BButtonGroup,
  BTabs,
  BTab,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { config } from "../shared/app.config";

import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BButton,
    BButtonGroup,
    BBadge,
    flatPickr,
    BTabs,
    BTab,
    BFormInput,
    BInputGroupAppend,
  },
  data() {
    return this.customData();
  },
  mounted() {
    this.stacked = window.innerWidth <= 900;
    window.addEventListener("resize", () => {
      this.stacked = window.innerWidth <= 900;
    });
  },
  computed: {
    ...mapGetters({
      items: "orders/getListOrders",
      totalItems: "orders/getTotalListOrders",
      client: "customers/getCurrentCustomer",
      user: "auth/getUser",
    }),
  },
  created() {
    this.perPage = JSON.parse(localStorage.getItem("PerPageOrder")) || 10; // value or null
    this.fecha_ini = JSON.parse(localStorage.getItem("fechaIniOrder")) || null; // value or null
    this.fecha_fin = JSON.parse(localStorage.getItem("fechaFinOrder")) || null; // value or null
    this.currentPage =
      JSON.parse(localStorage.getItem("currentPageOrder")) || 1; // value or null
    this.sortBy = JSON.parse(localStorage.getItem("sortByOrder")) || ""; // value or null
    this.sortDesc = JSON.parse(localStorage.getItem("sortDescOrder")) || "+"; // value or null

    this.listOrders(this.filterType);
  },
  watch: {
    currentPage() {
      this.listOrders(this.filterType);
    },
    perPage() {
      this.listOrders(this.filterType);
    },
    sortBy() {
      this.listOrders(this.filterType);
    },
    sortDesc() {
      this.listOrders(this.filterType);
    },
    fecha_ini() {
      this.listOrders(this.filterType);
    },
    fecha_fin() {
      this.listOrders(this.filterType);
    },
  },
  methods: {
    tabActivated(newTabIndex, oldTabIndex, event) {
      if (newTabIndex == 0) {
        this.filterType = "client";
        this.listOrders("client");
      } else {
        this.filterType = "all";
        this.listOrders("all");
      }
    },
    ...mapActions({
      searchOrders: "orders/searchOrders",
    }),
    ...mapMutations({
      setLoading: "app/setLoading",
    }),
    customData() {
      return {
        stacked: false,
        filterType: "client",
        fecha_ini: null,
        fecha_fin: null,
        config,
        pageOptions: [10, 15, 20],
        product: {},
        sortBy: "",
        sortDesc: "+",
        search: "",
        sortOptions: [
          { text: "Estado", value: "estado" },
          { text: "Date", value: "fecha" },
        ],
        orderOptions: [
          { text: "Desc", value: "+" },
          { text: "Asc", value: "-" },
        ],
        currentPage: 1,
        perPage: 10,
        sort: "",

        fieldsAll: [
          {
            key: "Pedido",
            label: this.$i18n.messages.Order,
          },
          {
            key: "Fecha",
            label: this.$i18n.messages.Fecha,
          },
          {
            key: "Su pedido",
            label: this.$i18n.messages.Ref,
            formatter(value, key, item) {
              console.log(value);
              if (value == "") {
                return item.Referencia;
              }
              return value;
            },
          },
          {
            key: "Lineas",
            label: this.$i18n.messages.Lines,
          },
          {
            key: "Importe",
            label: this.$i18n.messages.Amount + " (EUR)",
            /* formatter(value, key, item) {
              return value + " " + item.Moneda;
            }, */
          },
          {
            key: "Tasa_energetica",
            label: this.$i18n.messages.Tasa,
            /* formatter(value, key, item) {
              return value + " " + item.Moneda;
            }, */
          },
          /* {
            key: "Moneda",
            label: "Moneda",
          }, */
          {
            key: "Peso",
            label: this.$i18n.messages.weight,
          },
          {
            key: "Estado",
            label: this.$i18n.messages.State,
          },
          {
            key: "Actions",
            label: "",
            class: "btnActionRWD",
          },
        ],
      };
    },
    listOrders(type) {
      const {
        currentPage,
        perPage,
        sortBy,
        sortDesc,
        fecha_ini,
        fecha_fin,
      } = this;

      localStorage.setItem("PerPageOrder", JSON.stringify(perPage));
      localStorage.setItem("fechaIniOrder", JSON.stringify(fecha_ini));
      localStorage.setItem("fechaFinOrder", JSON.stringify(fecha_fin));
      localStorage.setItem("currentPageOrder", JSON.stringify(currentPage));
      localStorage.setItem("sortByOrder", JSON.stringify(sortBy));
      localStorage.setItem("sortDescOrder", JSON.stringify(sortDesc));

      const limit = perPage;
      const offset = (currentPage - 1) * perPage;
      let sort = "";
      if (sortBy) {
        sort = sortDesc + sortBy;
      }
      this.setLoading(true);
      if (type === "client") {
        var idClient = 0;
        if (this.client) {
          idClient = this.client.IDEMPRESA;
          this.searchOrders({
            limit,
            offset,
            sort,
            idClient,
            fecha_ini,
            fecha_fin,
            search: this.search,
          }).then(() => {
            this.setLoading(false);
          });
        } else {
          this.searchOrders({
            limit,
            offset,
            sort,
            fecha_ini: "",
            fecha_fin: "",
            search: this.search,
          }).then(() => {
            this.setLoading(false);
          });
        }
      } else {
        this.searchOrders({
          limit,
          offset,
          sort,
          fecha_ini,
          fecha_fin,
          search: this.search,
        }).then(() => {
          this.setLoading(false);
        });
      }
    },
    resetFilters() {
      localStorage.removeItem("PerPageOrder");
      localStorage.removeItem("fechaIniOrder");
      localStorage.removeItem("fechaFinOrder");
      localStorage.removeItem("currentPageOrder");
      localStorage.removeItem("sortByOrder");
      localStorage.removeItem("sortDescOrder");
      Object.assign(this.$data, this.customData());
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-flatpicker.scss";
</style>
